.songs{
    background-color: white;
    width: 400px;
    height: 180px;
    margin-top: 20px;
}
.list_songs{
    overflow: scroll;
    margin-top: 180px;
    width: 600px;
    height: 500px;
    margin-left: 400px;
}
.list_songs ul{
    margin-top: 80px;
}
.container {
    display: grid;
    grid-template-columns: 250px 100%;
    grid-template-rows: 80% 300px;
    gap: 0px 0px;
    grid-auto-flow: column;
    position: relative;
    grid-template-areas:
    
      ". ."
      ". .";
  }
  