.container {
    display: grid;
    grid-template-columns: 250px 100%;
    margin-top: 80px;
    grid-template-rows: 80% 300px;
    gap: 0px 0px;
    grid-auto-flow: column;
    position: relative;
    grid-template-areas:
      ". ."
      ". .";
  }
  