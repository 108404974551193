body {
  background-color: white;
animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


.songs {
  background-color: rgb(29, 29, 29);
  height: 80px;
  width: 300px;
  color: rgb(144, 202, 249);
  list-style: none;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 8px;
}

.close {

  color: rgb(144, 202, 249);
  background-color: transparent;
  text-decoration: none;
  border: none;
  cursor: pointer;
  position: relative;
  top: -30px;
  right: -128px;
  height: 20px;
  width: 20px;
}
.title_song {
  width: 10px;
  height: 10px;
  position: relative;
  top: -35px;
  left: -50px;

}
.list_songs {
  width: 600px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -80px;
  margin-left: -15px;

}
h3 {
  color: black;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 20px;
  width: 100%;

}
.songs_create {
  height: 80px;
  width: 100%;
  color: rgb(144, 202, 249);
  list-style: none;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 3px;
}