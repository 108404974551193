.loginbox {
    display: flex;
    margin: auto;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    animation: transformx 1.5s;

    
   

}

@keyframes transformx {
    from { transform: translateY(-20px); }
    to   { transform: translateY(0px); }
  }
.header1 {
    background-color: #1976d2;
    width: 300px;
    height: 180px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: column;

 
}

.header2 {


    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    font-size: 16px;
}
.header1 img{
    width: 50px;
    height: 50px;
   margin-left: 50%;
   margin-right: 50%;
   margin-bottom: 20px;
}