.top{
    background-color: #1976d2;
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
   left: 0;
   z-index: 10;
}
.top img{
    width: 30px;
    height: 30px;
    position: fixed;
    top: 15px;
    left: 20px;
}